.media-talks-item {
    text-align: justify;

    .card {
        margin-bottom: 8px !important;
        padding: 10px;
        font-family: serif;
        line-height: 1.8;

        background: #ffffff;
        padding: 1.25rem;
        color: #495057;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;

    }

    .img-thumbnail {
        background-color: var(--bs-body-bg);
        /* border: var(--bs-border-width) solid var(--bs-border-color); */
        border-radius: var(--bs-border-radius);
        max-width: 100%;
        height: auto;
        padding: 0;
        border-radius: 5%;
    }

    .ann-icon {
        margin-top: 1.3rem;
    }

    .create-dt {

        justify-content: center;
        text-align: right;

    }

    .p-image-preview-container {
        width: 50%;
    }

}