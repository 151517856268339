.student-achievements-item {
    padding: 10px;
    font-family: serif;


    .padding {
        padding: 10px;
    }

    .achiever-name {
        font-size: 1.2rem;
        font-weight: 600;
    }

    .achievement {
        font-size: 1.1rem;
    }

    .create-dt {
        font-size: 14px;
        float: right
    }

    .img-thumbnail {
        padding: 0.25rem;
        background-color: var(--bs-body-bg);
        border: none !important;
        border-radius: var(--bs-border-radius);
        max-width: 100%;
        height: auto;
    }


}