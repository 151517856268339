.footer-main {

    background-color: #000165;
    color: #ffffff;
    margin-top: 5%;

    .color-aqua {
        color: aqua !important;
    }

    .style-footer-logo {
        margin-top: -4%;
        z-index: 1;
        margin-bottom: 1%;
    }

    .style-footer-logo img {
        width: 10%;
    }

    .fa {
        padding: 10px;
        font-size: 20px;
        width: 50px;
        text-align: center;
        text-decoration: none;
        margin: 5px 2px;
        display: inline-block;
        border-radius: 4px;
    }

    /* Add a hover effect if you want */
    .fa:hover {
        opacity: 0.7;
    }

    /* Set a specific color for each brand */

    /* Facebook */
    .fa-facebook {
        background: #3B5998;
        color: white;
    }

    /* Twitter */
    .fa-twitter {
        background: #55ACEE;
        color: white;
    }

    .fa-youtube {
        background: #bb0000;
        color: white;
    }

    .fa-instagram {
        background: #125688;
        color: white;

    }

    .student-login-alumini {
        margin-left: 2%;
        font-size: 13px;
        font-family: serif;
        margin: 5px 2px;

    }

    .gyanmaydip {
        padding: 10px;
    }

    .yashogatha {
        width: 42%;
        margin-top: -30%;
        margin-left: 45%;
    }

    .yashogatha:hover {
        cursor: pointer;
    }


    /*@media (max-width: 991.98px) {
        margin-bottom: 52%;

        .yashogatha {
            width: 42%;
            margin-top: 0%;
        }

        .style-footer-logo {
            margin-top: -12%;
            z-index: 1;
            margin-bottom: 1%;
        }

        .style-footer-logo img {
            width: 30%;
        }
    }*/

    @media (max-width: 575.98px) {
        .yashogatha {
            width: 42%;
            margin-top: 0%;
            margin-left: 0%;
        }
    }

}