.navigation-bar {
    font-family: serif;
    font-size: 14px;

    .navbar {
        position: sticky;
        top: 0;
        z-index: 900;
    }

    .bg-body-tertiary {
        background-color: #000165 !important;
    }

    .nav-link {
        color: #fff !important;
        font-weight: 600;
    }

    .nav-link:hover {
        color: #f2ff00 !important;
        font-size: 14px;

    }

    .bar-color {
        color: red !important;
    }

    .justify-content-center {
        justify-content: center !important;
    }




    // Medium devices (tablets, less than 992px)
    @media (max-width: 991.98px) {
        .no-display {
            display: none !important;
        }

        .nav-link {
            float: left !important;
        }

    }
}