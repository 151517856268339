.notice-item {
    text-align: justify;

    .card {
        margin-bottom: 8px !important;
        padding: 10px;
        font-family: serif;
        line-height: 1.8;

    }


    .ann-icon {
        margin-top: 1.3rem;
    }

    .create-dt {

        justify-content: center;
        text-align: right;

    }
}