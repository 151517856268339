.about {
    margin: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: serif;
    margin-bottom: 3% !important;

    .about-img {
        padding: 9px 20px 0px 20px;
    }

    .about-body {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            padding: 15px;
        }

        p {
            text-align: justify;
            padding: 20px;
            line-height: 1.8;

        }
    }

    .heading-style {
        text-align: start;
        padding-left: 20px;
    }

    .about-img {
        border: 4px solid #a6a5c9 !important;
        border-radius: 14px;
        padding: 2px;
        max-width: 80%;
        height: auto;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

@media (min-width: 360px) {
    .about {
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 992px) {
    .about {
        margin: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}