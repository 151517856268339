.ck-editor {
    .content {
        padding: 20px;

        p{
            font-size: 14px;
            padding-left: 20px;
            padding-right: 20px;
            text-align: start;
        }
    }

    figure {
        table {
            width: 100%;
        }

        table tbody tr,
        td {
            border: 1px solid black;
            text-align: justify;
            padding: 5px;
        }

        table tbody tr:first-child {
            font-size: 14px;
            font-weight: 600;
            background-color: blueviolet;
            color: white;
        }
    }

    ol{
        li{
            text-align: start;
        }
    }

}