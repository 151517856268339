.home-layout {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;


    ul {
        text-decoration: none !important;

    }

    .goal-objective {
        margin: 2rem;
        padding: 1rem;

        .header {
            padding: 0.5rem;
            background-color: #000165 !important;
            color: #fff;
            text-transform: capitalize;

            h5 {
                font-size: 14px;
            }
        }

        .body {
            margin-top: 1rem;
            margin-bottom: 1rem;
            text-align: justify;
            text-decoration: none !important;
            font-size: 14px;

        }

    }


    .news {
        margin-top: 1rem;

        border: 2px solid #ff60002e;


        ul {

            list-style-type: circle;
            margin: 0;
            padding: 0;

        }

        li {
            float: left;
            margin-right: 2rem;

        }

        li a {
            display: block;
            padding: 8px;
            text-decoration: none;
            color: #000165;
            margin: auto;
            font-style: italic;
            font-size: 12px;
        }

        li a:hover {
            display: block;
            padding: 8px;
            text-decoration: underline;
            color: #ff4d00;
            margin: auto;
            font-style: italic;
        }

        .updates {
            margin: auto;
            color: #fff;
            background-color: #ff6000;
            font-weight: bold;
            padding: 0.4rem;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            border: 2px solid #ff4d00;
        }

        .update-text {
            margin: auto;
            width: 50%;
            padding: 10px;
        }
    }


    #sidebar {
        width: 150px;
        position: fixed;
        left: 70%;
        top: 380px;
        margin: 0 0 0 110px;
        overflow: transparent;
        z-index: 15000;
    }

    @media (max-width: 768px) {
        #sidebar {
            width: 100px;
            position: fixed;
            left: 40%;
            top: 380px;
            margin: 0 0 0 110px;
            overflow: transparent;
            z-index: 15000;
        }

    }

    .bounce {
        -webkit-animation-name: bounce;
        -moz-animation-name: bounce;
        -ms-animation-name: bounce;
        -o-animation-name: bounce;
        animation-name: bounce;
        -webkit-animation: bounce 2s infinite;
        animation: bounce 2s infinite;

        img {

            max-width: 100%;
            height: auto;
            vertical-align: middle;
            border: 0;
            -ms-interpolation-mode: bicubic;
        }
    }

    @-webkit-keyframes bounce {

        0%,
        20%,
        50%,
        80%,
        100% {
            -webkit-transform: translateY(0);
        }

        40% {
            -webkit-transform: translateY(-30px);
        }

        60% {
            -webkit-transform: translateY(-15px);
        }
    }

    @keyframes bounce {

        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0);
        }

        40% {
            transform: translateY(-30px);
        }

        60% {
            transform: translateY(-15px);
        }
    }


}