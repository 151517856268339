.student-achievements-layout {

    .padding {
        padding: 10px;
    }

    .float-right {
        padding: 10px;
        padding-bottom: 0px;
        float: right;
    }

}