.hm-principal {
    padding: 2rem;

    font-family: BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 13px;

    .hm-border {
        padding: 5px;
        border: 1px sold red;
    }

    .hm-profile-pic {
        height: 200px;
        width: 200px;
        border: 6px double goldenrod;
        padding: 4px;
        border-radius: 50%;
    }

    .text-data {
        text-align: justify;
    }


}