.footer-links {
    background-color: #000165;
    /*overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;*/

    .footer-btn-group {
        .btn-style {
            margin: 10px !important;
            background-color: blue;
            border: blue;
            font-size: 13px;
            font-family: serif;
        }
    }

}