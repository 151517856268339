.trustee-awards {
    margin-bottom: 10px;
    font-family: BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 13px;

    .card {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .profile {
            margin-top: 20px;
        }

        .card-body {
            justify-content: flex-start;
            align-items: flex-start;

            .info {
                text-align: start;
                display: block;
                list-style-type: disc;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                //padding-inline-start: 10px;
            }
        }
    }
}