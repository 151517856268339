.grievance-form {
    .form-container {
        width: 80%;
        background-color: white;
        margin: auto;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
        padding: 10px;
    }

    .register-form {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 10px;
    }

    .success-message {
        font-family: "Roboto", sans-serif;
        background-color: #40d88c;
        padding: 15px;
        color: white;
    }

    .form-field {
        margin: 10px 0 10px 0;
        padding: 8px;
        font-size: 12px;
        border: 0;
        font-family: "Roboto", sans-serif;
    }

    span {
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        color: red;
        margin-top: 10px;
        text-align: left;
    }

    input,
    select,
    textarea {
        background: #f2f2f2;
        margin: 10px 0 10px 0;
        padding: 8px;
        font-size: 12px;
        border: 0;
        font-family: "Roboto", sans-serif;
    }

    .error {
        border-style: solid;
        border: 2px solid #ffa4a4;
    }

    button {
        background: #3c2f95;
        color: white;
        cursor: pointer;
    }

    button:disabled {
        cursor: default;
    }

    .submit {
        width: 40%;
        float: right;
        border-radius: 5px;
    }

}