.header-top {
    background-color: #6599ff !important;

    .img-fluid {
        width: 50%;
        background-color: transparent;
        border: none;
    }

    .aazadi-logo {
        width: 40% !important;
    }

    .center-block {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .site-header-title {
        color: #fff;
    }

    .site-header-desc {
        color: #000165;
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {}

    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {}

    // Medium devices (tablets, less than 992px)
    @media (max-width: 991.98px) {}

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1199.98px) {}
}