.image-collage {

/* Add this to image-collage.scss */
.custom-image {
    width: 100%;       /* Ensures the image takes up full width of the container */
    height: 150px;     /* Fixed height for all images */
    object-fit: cover; /* Ensures the image covers the area without stretching */
    border-radius: 8px; /* Optional: for rounded corners */
}

.placeholder-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    height: 150px;  /* Match the image height */
    width: 100%;    /* Match the image width */
    color: #888;
    font-size: 14px;
    border: 1px solid #ddd;
    text-align: center;
    border-radius: 8px; /* Optional: for rounded corners */
}



    td {
        padding: 1px !important;
        border-bottom: none !important;
    }

    .last-item {
        opacity: 0.3;
    }

    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .custom-container {
        position: relative;
        text-align: center;
        color: gray;
        font-weight: bold
    }

    .custom-container:hover {
        position: relative;
        text-align: center;
        color: blueviolet;
        font-weight: bold
    }

    .p-card .p-card-body {
        padding: 0.25rem;
    }

    .p-card .p-card-content {
        padding: 0rem 0;
    }

    .img-thumbnail {
        background-color: var(--bs-body-bg);
        /* border: var(--bs-border-width) solid var(--bs-border-color); */
        border-radius: var(--bs-border-radius);
        max-width: 100%;
        height: auto;
        padding: 0;
        border-radius: 0;
    }

    .year-txt-style {
        font-family: serif;
        font-weight: 800;
        color: royalblue;
    }
}