.facility-infra {
    padding: 10px;

    .create-dt {
        font-size: 10px;
        color: gray;
        text-align: right;
        padding: 10px;
    }

    .my-card {
        position: relative;
        overflow: hidden;
    }

    .my-card .my-card-image {
        position: relative;
        height: 250px;
    }

    .my-card .my-card-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .my-card .my-card-caption {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 1rem;
        box-sizing: border-box;
    }
}