.page-header {

    margin-top: 2%;
    margin-bottom: 2%;

    header {
        margin-top: 1rem;
        display: flex;
        color: #000165 !important;

    }

    header:before,
    header:after {
        content: '';
        margin: auto 1em;
        border-bottom: solid 1px;
        flex: 1;
    }

    h5 {
        position: relative;
        padding: 0.25em 1em;
        overflow: hidden;
        background: linear-gradient(currentcolor, currentcolor) no-repeat top center, linear-gradient(currentcolor, currentcolor) no-repeat bottom center;
        background-size: calc(100% - 1.65em) 1px;
        font-weight: 700;
    }

    h5 p {
        font-size: 14px;
    }

    h5:before,
    h5:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: solid 1px;
        border-top: none;
        border-bottom: none;
        transform: skew(45deg)
    }

    h5:after {
        transform: skew(-45deg)
    }
}