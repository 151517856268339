.trustee-profile {
    min-width: 250px;
    margin-bottom: 20px;


    .card {
        padding-top: 20px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;


        .profile {
            margin-top: 20px;
            height: 160px;
            width: 160px;
            border-radius: 100%;
            // border: 3px dotted cornflowerblue;
            //padding: 4px;
        }

        .card-text {
            margin: 5px;
        }

        button {
            margin-top: 10px;
            width: 100px;
            font-size: 12px;
        }

        .short-card {
            height: 200rem;
        }

        .double-dotted-border {
            border-style: dotted;
            border-width: 3px;
            border-color: #de6a6a;
            padding: 8px;
            position: relative;
        }

        .double-dotted-border::before {
            content: '';
            position: absolute;
            top: -6px;
            left: -6px;
            right: -6px;
            bottom: -6px;
            border-style: dotted;
            border-width: 2px;
            border-color: transparent;
            border-color: green;
        }

    }
}