.gallery-grid {

    margin-bottom: 2rem;

    .p-grid {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
        background-color: #eaeaea;
    }

    .p-card {
        margin: 0.2rem;
    }

    .p-paginator {
        background-color: #eaeaea;
        ;
        padding-top: 0;
    }

    /**Ribbon styles**/
    .ribbon {
        font-size: 20px;
        position: relative;
        display: inline-block;
        text-align: center;
    }

    .text {
        display: inline-block;
        padding: 0.5em 1em;
        min-width: 20em;
        line-height: 1.2em;
        background-color: #000165 !important;
        position: relative;
        color: #ffffff;
    }

    .ribbon:after,
    .ribbon:before,
    .text:before,
    .text:after,
    .bold:before {
        content: '';
        position: absolute;
        border-style: solid;
    }

    .ribbon:before {
        top: 0.3em;
        left: 0.2em;
        width: 100%;
        height: 100%;
        border: none;
        background: #EBECED;
        z-index: -2;
    }

    .text:before {
        bottom: 100%;
        left: 0;
        border-width: .5em .7em 0 0;
        border-color: transparent #FC9544 transparent transparent;
    }

    .text:after {
        top: 100%;
        right: 0;
        border-width: .5em 2em 0 0;
        border-color: #FC9544 transparent transparent transparent;
    }

    .ribbon:after {
        top: 0.5em;
        right: 0em;
        border-width: 1.1em 1em 1.1em 3em;
        border-color: #FECC30 transparent #FECC30 #FECC30;
        z-index: -1;
    }

    .bold:before {
        border-color: #EBECED transparent #EBECED #EBECED;
        top: 0.7em;
        right: -2.3em;
    }

    .address-txt {
        font-size: small;
        font-weight: 500;
    }


    //new style section heading
    .container1 {
        margin-bottom: 1.8%;

        h1 {
            position: relative;
            margin: 0 -25px;
            font-size: 16px;
            background: #000165 !important;
            color: #FFFFFF;
            text-shadow: 1px 1px 0px #1A5D6E;
            box-shadow: 0 0 10px #AAA;
            padding: 1%;
        }

        h1:before {
            content: "";
            display: block;
            position: absolute;
            bottom: -25px;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: #1A5D6E transparent;
            border-width: 25px 0 0 25px;
            left: 0;
        }

    }

    /* The important bit */




}