.gallery-details {
    .img-container {
        padding: 5px;

    }

    .thumbnail {
        display: block;
        padding: 4px;
        line-height: 1.42857143;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        -webkit-transition: border .2s ease-in-out;
        -o-transition: border .2s ease-in-out;
        transition: border .2s ease-in-out;
        cursor: pointer;
    }

    .thumbnail>img {
        margin-right: auto;
        margin-left: auto;
    }

    .thumbnail>img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    img {
        vertical-align: middle;
    }

    img {
        border: 0;
    }

    .drop-down-style {
        width: 100%;
        margin-bottom: 8px;
    }

    .container1 {
        margin-bottom: 1.8%;

        h1 {
            position: relative;
            margin: 0 -25px;
            font-size: 16px;
            background: #000165 !important;
            color: #FFFFFF;
            text-shadow: 1px 1px 0px #1A5D6E;
            box-shadow: 0 0 10px #AAA;
            padding: 1%;
        }

        h1:before {
            content: "";
            display: block;
            position: absolute;
            bottom: -25px;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: #1A5D6E transparent;
            border-width: 25px 0 0 25px;
            left: 0;
        }

    }

    .p-grid {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
        background-color: #dfdce7;
    }

    .p-card {
        margin: 0.2rem;
    }

    .p-paginator {
        background-color: #dfdce7;
        padding-top: 0;
    }

}